import React, { Fragment } from "react";
import { withFormik } from "formik";
import { FormikInput } from "../../components/formik/formik-input";
import { Button } from "@tscore/react-components";
import classNames from "classnames";
import { resetSchema } from "../../schemas/login.schema";
import { fetchSetForgotPassword } from "../../store/actions/auth";

// import startCase from "lodash/startCase";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import { FORGOT_PASSWORD_URL, LOGIN_URL } from "./consts";
import { NavLink } from "react-router-dom";
// import { MINIMUM_PASSWORD_REQUIREMENTS } from "../../lang/authentication";
// import { FORGOT_PASSWORD_URL } from "./consts";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { TFunction } from "i18next";

const resetPasswordFormState = {
  key: "",
  username: "",
  password: "",
  confirmPassword: "",
};

const INPUT_SETTINGS = {
  key: {},
  username: {},
  password: {
    type: "password",
  },
  confirmPassword: {
    type: "password",
  },
};

const ErrorMessage: React.FC<{ error: string }> = ({ error }) => {
  const parts = error.split(/({{_ANCHOR:.*?_}})/);
  return (
    <li>
      {parts.map((part, i) => {
        if (part.startsWith("{{_ANCHOR:")) {
          const anchorText = part.slice(10, -3);
          return (
            <NavLink key={i} to={FORGOT_PASSWORD_URL}>
              {anchorText}
            </NavLink>
          );
        }
        return <Fragment key={i}>{part}</Fragment>;
      })}
    </li>
  );
};

const ErrorList: React.FC<{ submitCount: number; listOfErrors: string[] }> = ({ submitCount, listOfErrors }) => {
  return (
    <ul
      className={classNames("form-error form-error__reset list squared", {
        // "list squared": listOfErrors.length >= 2,
        "is-visible": submitCount >= 1 && listOfErrors.length >= 1,
      })}>
      {listOfErrors.map((error: string) => (
        <ErrorMessage key={error} error={error} />
      ))}
    </ul>
  );
};

export const FormReset = (props: any) => {
  const { t } = useTranslation();
  // console.log(props);
  const {
    isFetching,
    // onKeyDown,
    // receivedErrors,
    values,
    // touched,
    // errors,
    // dirty,
    isSubmitting,
    handleSubmit,
    submitCount,
    onInputChange,
    // apiErrors,
    listOfErrors,
    match,
  } = props;
  const passwordResetUsernames = useSelector((state: ApplicationState) => state.credentialsReducer.passwordReset);

  const onKeyDown = ({ keyCode }: any): boolean | void => keyCode === 13 && handleSubmit;
  // const listOfErrors: string[] = [...Object.values(errors), ...receivedErrors];
  // console.log(errors);
  // const listOfErrors: string[] = Object.values({
  //   ...errors,
  //   ...(apiErrors && { general: handleError(apiErrors) }),
  // });
  const params = match.params;
  const isSubmitted = !!passwordResetUsernames[values.username];
  if (isSubmitted) {
    return (
      <p style={{ paddingBottom: "15px" }}>
        <Trans
          i18nKey="login:resetSubmitted"
          components={[
            <NavLink key={0} to={LOGIN_URL}>
              login page
            </NavLink>,
          ]}></Trans>
      </p>
    );
  }
  return (
    <form className="login-form" onKeyDown={onKeyDown} onSubmit={handleSubmit} autoComplete="off">
      <input tabIndex={-1} aria-hidden="true" className="visually-hidden" name="username" type="text" />
      <input tabIndex={-1} aria-hidden="true" className="visually-hidden" name="password" type="password" />
      <div className="account-section password-requirements-section reset-requirements-section">
        <aside>
          <p>{t("myAccount:Minimum password requirements")}:</p>
          <ul className="list squared" style={{ marginTop: "0.6rem", paddingLeft: "12px" }}>
            {(t("myAccount:MINIMUM_PASSWORD_REQUIREMENTS", { returnObjects: true }) as string[]).map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </aside>
      </div>
      <hr style={{ marginBottom: "1.2rem" }} />
      <ErrorList submitCount={submitCount} listOfErrors={listOfErrors} />

      {Object.keys(resetPasswordFormState).map((item) => {
        // const isHidden = item === "username" && values[item] === params["username"];
        // if (isHidden) {
        //   return null;
        // }
        const settings = INPUT_SETTINGS[item as keyof typeof INPUT_SETTINGS];
        const isDisabled =
          (item === "key" && values[item] === params["key"]) ||
          (item === "username" && values[item] === params["username"])
            ? true
            : false;
        const placeholder = t("login:" + item);
        // const placeholder = startCase(item);
        return (
          <div key={item} className="login-input reset-input">
            <label>{placeholder}</label>
            <FormikInput
              {...settings}
              disabled={isDisabled}
              onKeyDown={onInputChange}
              name={item}
              placeholder={placeholder}
              autoComplete="new-password"
              scale="large"
            />
          </div>
        );
      })}
      <div style={{ marginBottom: "8px" }}>
        {/* add size="large" */}
        <Button className="bold" type="submit" isLoading={isSubmitting || isFetching} scale="large" length="fullwidth">
          {t("login:SET NEW PASSWORD")}
        </Button>
      </div>
    </form>
  );
};

export const formikEnhancerReset = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => resetSchema(t),
  mapPropsToValues: ({ match }: any) => {
    return {
      ...resetPasswordFormState,
      key: match.params.key || "",
      username: match.params.username || "",
    };
  },
  handleSubmit: (values, { setSubmitting, props }) => {
    const payload = {
      ...values,
    };
    // console.log(props);
    props.fetchSetForgotPassword(payload);
    // dispatch(fetchLogIn(values.username, values.password));
    setSubmitting(false);
  },
  displayName: "ResetPage",
});

export const mapDispatchToPropsReset = (dispatch: any) => ({
  fetchSetForgotPassword: (values: typeof resetPasswordFormState) => dispatch(fetchSetForgotPassword(values)),
});

// export const LoginPage = connect(null, mapDispatchToPropsLogin)(formikEnhancerLogin);
