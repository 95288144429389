import ReportsService from "./reports.service";
import LocalService from "./local.service";
import { downloadCleanup, generateIframe } from "./iframe-helpers";
import { GLOBAL_PDF_SETTINGS, generatePdfWrapper } from "../../../globals/pdf-generator";
import { formatToString, momentUpdatedTimeDateFormat } from "../../../lang/DateTimeFormats";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { ReportType, ReportFilters } from "../../types/reports-state";
import { ApplicationState } from "../../reducers";
import { PaginatedSort } from "../../../types/paginated-params";

const getReportList = (
  id: "all" | number,
  reportType: ReportType,
  page: number,
  data: any,
  filters: ReportFilters
) => ({
  type: "GET_REPORT_LIST",
  id,
  page,
  reportType,
  data,
  filters,
});

export const fetchReportList = (
  id: "all" | number,
  reportType: ReportType,
  page = 1,
  filters: ReportFilters = {},
  sort: PaginatedSort = {}
): ApiAction => {
  // const menuReducer = getState().menuReducer;
  // const module = menuReducer.meta.module;
  // const SYSTEM_ORIGIN_MAP: { [key: string]: SystemOrigin } = {
  //   Resilience: "RESILIENCE",
  //   "NHS Region": "REGIONS",
  // };
  // const systemOrigin: SystemOrigin | undefined = SYSTEM_ORIGIN_MAP[module];
  return ReportsService.getReportList(
    { id, reportType, page, filters, sort },
    {
      label: "GET_REPORT_LIST",
      onSuccess: (response: any) => getReportList(id, reportType, page, response, filters),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { id, reportType },
    }
  );
};

const getRequestReport = (id: number, reportType: ReportType, userInfo: any, organisationName = "") => ({
  type: "REQUEST_REPORT",
  id,
  reportType,
  userInfo,
  organisationName,
});

export const fetchRequestReport = (id: number, reportType: ReportType) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const userInfo = getState().credentialsReducer.app_user_info;
    function getOrganisationMeta() {
      const raw = getState().menuReducer.rawMenu["nhsr-service"];
      const organisationList =
        reportType === "winterReport" ? raw.winterReportOrganisationList : raw.otherReportOrganisationList;
      return organisationList.find((o: any) => o.id === id || o.areaId === id) || { name: "?" };
    }
    const organisation = getOrganisationMeta();
    dispatch(
      ReportsService.getRequestReport(
        { organisationId: id, reportType },
        {
          label: "REQUEST_REPORT",
          onSuccess: (_response: boolean) =>
            getRequestReport(
              id,
              reportType,
              {
                id: userInfo.userId,
                fullname: userInfo.fullname,
                email: userInfo.email || userInfo.username,
                username: userInfo.username,
              },
              organisation.name || organisation.areaName
            ),
          other: { id, reportType },
        }
      )
    );
  };
};

const deleteReport = (id: "all" | number, reportType: ReportType, reportId: number) => ({
  type: "DELETE_REPORT",
  id,
  reportType,
  reportId,
});

export const fetchDeleteReport = (id: "all" | number, reportType: ReportType, reportId: number): ApiAction => {
  return ReportsService.deleteReport(
    {
      reportId,
      reportType,
    },
    {
      label: "DELETE_REPORT",
      onSuccess: (_response: boolean) => deleteReport(id, reportType, reportId),
      other: { id, reportType },
    }
  );
};

const requestMoreInfoWinterReport = (
  id: "all" | number,
  reportId: number,
  questions: { question: string }[],
  userInfo: any
) => ({
  type: "REQUEST_MORE_INFO_WINTER_REPORT",
  reportType: "winterReport",
  id,
  reportId,
  questions,
  userInfo,
});

export const fetchRequestMoreInfoWinterReport = (
  id: "all" | number,
  reportId: number,
  questions: { question: string }[]
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const userInfo = getState().credentialsReducer.app_user_info;
    dispatch(
      ReportsService.requestMoreInfo(
        { reportId, questions },
        {
          label: "REQUEST_MORE_INFO_WINTER_REPORT",
          onSuccess: (_response: any) => requestMoreInfoWinterReport(id, reportId, questions, userInfo),
          other: { id },
        }
      )
    );
  };
};

const downloadNHSReport = (id: "all" | number, reportId: number | string, data: any, fileName: string) => ({
  type: "DOWNLOAD_NHS_REPORT",
  id,
  reportId,
  data,
  fileName,
});

const updateReportDownloadProgress = (id: "all" | number, reportId: number | string, percentage = 0) => ({
  type: "UPDATE_REPORT_DOWNLOAD_PROGRESS",
  id,
  reportId,
  percentage,
});

const getLocalManifestData = (id: "all" | number, reportId: number | string, cssAssets: string[]) => ({
  type: "GET_LOCAL_MANIFEST_DATA",
  id,
  reportId,
  cssAssets,
});

export const fetchDownloadReportFromWithin = (id: "all" | number, reportId: number | string, value: string) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    downloadCleanup(reportId);
    const state = getState().reportsReducer[id] || getState().reportsReducer[-1];
    const moduleId = getState().menuReducer.meta.moduleId;
    const cssLinks = state.downloadCSSAssets;
    const report = state.reports.find((report) => report.id === parseFloat(reportId + "")) || {
      areaName: "",
      submittedDatetime: "",
      reportTitle: "",
    };
    let reportTitle = "Report";
    if (report.reportTitle && report.reportTitle.trim().length > 0) {
      reportTitle = report.reportTitle;
    }
    const fileName =
      reportTitle.split(" ").join("-") +
      "-" +
      report.submittedDatetime
        .split(" ")
        .join("-")
        .replace(/[/\\?%,*:|"<>]/g, "-");
    const headerPdf = {
      title: reportTitle, //or whatever the template name or report name from db
      subtitle: null,
      meta: {
        moduleId,
        title: report.areaName,
        subtitle: "Submitted on: " + formatToString(report.submittedDatetime, momentUpdatedTimeDateFormat),
      },
    };
    const percentToShow = (percentage: number): number => {
      if (percentage >= 90) {
        return 90;
      }
      if (percentage <= 10) {
        return 10;
      }
      return percentage;
    };
    const onDownloadProgress = (progressEvent: any) => {
      const total = progressEvent.total !== 0 ? progressEvent.total : progressEvent.loaded + 100;
      const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
      dispatch(updateReportDownloadProgress(id, reportId, percentToShow(percentCompleted)));
    };
    dispatch(
      ReportsService.downloadNHSReport(
        {
          body: { ...GLOBAL_PDF_SETTINGS, cssLinks, value: generatePdfWrapper(value, headerPdf) },
        },
        {
          label: "DOWNLOAD_NHS_REPORT",
          onSuccess: (response: any) => downloadNHSReport(id, reportId, response, fileName),
          other: { id, reportId },
        },
        onDownloadProgress
      )
    );
  };
};

export const fetchDownloadReportWithTokenCheck = (
  id: "all" | number,
  reportType: ReportType,
  reportId: number | string,
  src: string
) => {
  // ! temporary hack, will change the service to something else later
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState().reportsReducer[id] || getState().reportsReducer[-1];
    const previousProgress = state.downloadProgress[reportId];
    dispatch(updateReportDownloadProgress(id, reportId, previousProgress === 100 ? 1 : 5));
    dispatch(
      ReportsService.downloadNHSReportTokenCheck(
        { id, reportType },
        {
          label: "DOWNLOAD_NHS_REPORT_TOKEN_CHECK",
          onSuccess: () => fetchDownloadReport(id, reportId, src),
        }
      )
    );
  };
};

const fetchDownloadReport = (id: "all" | number, reportId: number | string, src: string) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const onSuccess = function () {
      const originSrc = process.env.REACT_APP_ENV === "local" ? "" : process.env.REACT_APP_BASENAME!.replace(/\/$/, "");
      generateIframe(originSrc + src, reportId, dispatch, fetchDownloadReportFromWithin);
    };
    const state = getState().reportsReducer[id] || getState().reportsReducer[-1];
    const previousProgress = state.downloadProgress[reportId];
    dispatch(updateReportDownloadProgress(id, reportId, previousProgress === 100 ? 1 : 5));
    const cssLinks = state.downloadCSSAssets;
    if (cssLinks.length > 0) {
      // ? IF manifest downloaded before, don't need to download again
      onSuccess();
    } else {
      dispatch(
        LocalService.getManifest({
          label: "GET_LOCAL_MANIFEST_DATA",
          onSuccess: (manifest: { entrypoints: string[]; files: { [key: string]: string } }) => {
            if (process.env.REACT_APP_ENV === "local") {
              console.warn("DOWNLOADING MANIFEST CSS FILES NOT SUPPORTED IN LOCAL");
            }
            const cssAssets = [
              ...GLOBAL_PDF_SETTINGS.cssLinks,
              ...Object.entries(manifest.files).reduce((final: string[], [key, url]) => {
                if (key.endsWith(".css")) {
                  final.push(window.location.origin + url);
                }
                return final;
              }, []),
            ];
            onSuccess();
            // dispatch(updateReportDownloadProgress(id, reportId, 10));
            return getLocalManifestData(id, reportId, cssAssets);
          },
          other: { id, reportId },
        })
      );
    }
  };
};
